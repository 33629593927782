/**
 * @file This is the my user service module
 * @copyright Andreas Horvath 2016-2024
 * @version 0.2.0
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, firstValueFrom } from 'rxjs';
import { UserInfoInterface } from '../../interfaces';
import { UserBackendService, AuthBackendService } from '../../backend-services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class MyUserService {
  private userSource: BehaviorSubject<UserInfoInterface> = new BehaviorSubject(
    UserInfoInterface.builder().build()
  );
  public user$: Observable<UserInfoInterface> = this.userSource.asObservable();

  constructor(
    private userBackendService: UserBackendService,
    private authBackendService: AuthBackendService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.update();
  }

  public async update() {
    try {
      const user = await this.userBackendService.getPlayerInfo();
      if (user.isLocked) {
        this.userGotLockedLogout(); // if user is locked, logout !!
        throw new Error('User is locked');
      }
      this.userSource.next(user);
    } catch (e) {
      console.log('Could not get user info:', e);
    }
  }

  public async getUser(): Promise<UserInfoInterface> {
    return firstValueFrom(
      this.user$.pipe(filter((user) => !!user && !!user.userID))
    );
  }

  private async userGotLockedLogout() {
    try {
      // @TODO not working well, use authSerivce.logout() instead (BUT WHY NOT WORKING?)
      await this.authBackendService.logout();
      await this.router.navigate(['/'], {
        queryParams: { logout: true },
      });
      this.toastr.warning(
        'Du wurdest ausgeloggt, weil dein Account gesperrt wurde.'
      );
    } catch (e) {
      console.warn('Could not logout:', e);
    }
  }
}
